export const projectIdEnUS = {
  media: 'Media',
  documents: 'Documents',
  punchList: 'Punch list',
  closeout: {
    title: 'Close out',
    description: 'You are about to close the project, are you sure you want to close the project?',
    buttons: {
      cancel: 'Cancel',
      confirm: 'Confirm',
    },
    successDescription: 'The project has been successfully closed.',
    errorDescription: 'There was an error closing the project. Please try again.',
  },

  crews: 'Crews',
  workContacts: 'Work Contacts',
  notes: 'Notes',
  problems: 'Problems',
  laborBill: 'Final Labor Bill',
  success: 'Success',
  checkedInCorrectly: 'Checked in correctly',
  error: 'Error',
  projectNotReady: 'Project is not ready',
  cantBeClosedOut: 'The project can not be closed yet because you have pending punch lists.',
  contact: 'CONTACT',
  contactNotFount: 'There was an error loading the contact information contact support',
  directions: 'DIRECTIONS',
  checkIn: 'CHECK IN',
  checkInUpdate: {
    success: 'Coordinates were changed successfully',
    error: 'Error with change coordinates',
    title: 'Check in to',
    subtitle: 'Calibrate location',
    makeSure: 'To do a Check In, you must be inside the perimeter or near by the house. If you consider this is an error, just press "Correct Check In and Finish"',
    finish: 'Correct Check In and Finish',
    projectError: 'You are not in the project area',
    leadError: 'You are not in the lead area',
  },
  capture: 'CAPTURE',
  closeProject: 'CLOSE OUT',
  completed: 'Completed',
  taskLists: 'Task Lists',
  imageRequired: 'You should have at least 1 photo to complete the task.',
  reviewCrews: 'Review the Crews',
  crewManager: 'Crew Manager',
  project: 'Project',
  workmanshipRating: 'Workmanship Rating',
  workmanshipComment: 'Workmanship comment',
  leaveComment: 'Leave your commnet',
  timelinessRating: 'Timeliness Rating',
  timelinessComment: 'Timeliness comment',
  insertSignature: 'Insert you signature below to finish the process',
  goBack: 'Go back',
  continueProcess: 'Continue the process',
  reviewAndContinue: 'Review and continue',
  finalizeProject: 'Finalize project',
  errorCheckingIn: 'unknown error',
  mobileNumber: 'Mobile number',
  phoneNumber: 'Phone number',
  projectsRelated: 'Multi project',
  selectTypePhoto: 'Select type of photo',
  selectTypePhotoPlaceholder: 'Select type of photo',
  shareImages: 'Share Images',
  shareLink: 'Share Link',
  sharedSuccessfully: 'Images have been shared successfully!',
  generatedLink: 'The following link has been generated',
  linkCopyToClipboard: 'The link has been copied to clipboard',
}

export const projectIdEsMX = {
  media: 'Media',
  documents: 'Documentos',
  punchList: 'Lista de pendientes',
  closeout: {
    title: 'Cerrar proyecto',
    description: 'Estás a punto de cerrar el proyecto, ¿estás seguro de que deseas cerrarlo?',
    buttons: {
      cancel: 'Cancelar',
      confirm: 'Confirmar',
    },
    successDescription: 'El proyecto se ha cerrado correctamente.',
    errorDescription: 'Hubo un error al cerrar el proyecto. Por favor, inténtalo de nuevo.',
  },
  crews: 'Crews',
  workContacts: 'Contactos de trabajo',
  notes: 'Notas',
  problems: 'Problemas',
  laborBill: 'Factura Final de Trabajo',
  success: 'Éxito',
  checkedInCorrectly: 'Checked exitoso',
  error: 'Error',
  projectNotReady: 'Proyecto no esta listo',
  cantBeClosedOut: 'El proyecto no puede ser cerrado porque tiene pendientes.',
  contact: 'CONTACTO',
  contactNotFount: 'Al parecer hubo un error al cargar la información del contacto, contacta con soporte',
  directions: 'DIRECCIONES',
  checkIn: 'REGISTRO',
  checkInUpdate: {
    success: 'Las coordenadas se cambiaron con éxito.',
    error: 'Error al cambiar las coordenadas.',
    title: 'Check en',
    subtitle: 'Calibrar ubicación',
    makeSure: 'Para hacer un Check In, debes estar dentro del perímetro o cerca de la casa. Si considera que se trata de un error, pulse "Corregir Check In y Finalizar".',
    finish: 'Corregir Check In y  Finalizar',
    projectError: 'No se encuentra en la zona del proyecto',
    leadError: 'No se encuentra en la zona de la lead',
  },
  capture: 'CAPTURAR',
  closeProject: 'CERRAR',
  completed: 'COMPLETADO',
  taskLists: 'LISTA DE TAREAS',
  imageRequired: 'Debes tener al menos 1 foto para completar la tarea.',
  reviewCrews: 'Revisa los Crews',
  crewManager: 'Crew Manager',
  project: 'Proyecto',
  workmanshipComment: 'Comentario de calidad de trabajo',
  workmanshipRating: 'Calidad de trabajo',
  leaveComment: 'Deja tu comentario',
  timelinessRating: 'Puntualidad',
  timelinessComment: 'Comentario de puntualidad',
  insertSignature: 'Inserta tu firma para finalizar el proceso',
  goBack: 'Regresar',
  continueProcess: 'Continuar el proceso',
  reviewAndContinue: 'Revisar y continuar',
  finalizeProject: 'Finalizar proyecto',
  errorCheckingIn: 'Error desconocido',
  mobileNumber: 'Número de celular',
  phoneNumber: 'Número de teléfono',
  projectsRelated: 'Multi proyecto',
  selectTypePhoto: 'Selecciona el tipo de foto',
  selectTypePhotoPlaceholder: 'Selecciona el tipo de foto',
  shareImages: 'Compartir imágenes',
  shareLink: 'Compartir enlace',
  sharedSuccessfully: '¡Las imágenes se han compartido con éxito!',
  generatedLink: 'Se ha generado el siguiente enlace',
  linkCopyToClipboard: 'El enlace se ha copiado al portapapeles',
}
